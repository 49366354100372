/* Import Font Dancing Script */
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);

* {
  margin: 0;
  /* border: 1px solid red; */
}

body {
  /* background-color: #e8f5ff; */
  font-family: Arial;
  /* overflow: hidden; */
}

/* NavbarTop */
.navbar-top {
  /* background-color: #ff0000; */
  color: #333;
  /* backdrop-filter: blur(3px); */
  box-shadow: 0px 4px 8px 0px grey;
  height: 70px;
}

.title {
  font-family: "Dancing Script", cursive;
  padding-top: 15px;
  position: absolute;
  left: 45%;
}

.navbar-top ul {
  float: right;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 18px 50px 0 40px;
}

.navbar-top ul li {
  float: left;
}

.navbar-top ul li a {
  color: #333;
  padding: 14px 16px;
  text-align: center;
  text-decoration: none;
}

.icon-count {
  color: #fff;
  float: right;
  font-size: 11px;
  left: -25px;
  padding: 2px;
  position: relative;
}

/* End */

/* Sidenav */
.sidenav {
    /* backdrop-filter: blur(3px); */
  color: #333;
  border-bottom-right-radius: 25px;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;
  position: absolute;
  top: 70px;
  width: 250px;
  box-shadow: 0px 4px 8px 0px grey;
}

.profile {
  margin-bottom: 20px;
  margin-top: -12px;
  text-align: center;
  color: white;
}

.profile img {
  border-radius: 50%;
  box-shadow: 0px 0px 5px 1px grey;
}

.name {
  font-size: 20px;
  font-weight: normal;
  padding-top: 20px;
  padding-top: 40px;
padding-bottom: 30px;
}

.job {
  font-size: 18px;
  font-weight: bold;
  padding-top: 10px;
  /* padding-top: 40px; */
    padding-bottom: 30px;
}

.url,
hr {
  text-align: center;
}

.url hr {
  margin-left: 20%;
  width: 60%;
}

.url a {
  color: white;
  display: block;
  font-size: 20px;
  margin: 10px 0;
  padding: 6px 8px;
  text-decoration: none;
  font-family: 'Arial', sans-serif;
}

.url a:hover,
.url .active {
  /* background-color: #e8f5ff; */
  border-radius: 28px;
  color: #000;
  margin-left: 14%;
  width: 65%;
}

/* End */

/* Main */
.main {
  margin-top: 10%;
  margin-left: 29%;
  font-size: 28px;
  padding: 0 10px;
  width: 58%;
}

.main h2 {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}
.title h1 {
  color: white;
}

.main .card {
  /* background-color: #fff; */
  border-radius: 18px;
  box-shadow: 1px 1px 8px 0 grey;
  height: 300px;
  margin-bottom: 20px;
  padding: 20px 0 20px 50px;
  /* backdrop-filter: blur(3px); */
}

.main .card table {
  border: none;
  font-size: 16px;
  height: 270px;
  width: 80%;
  font-family: 'Arial', sans-serif;
}

.edit {
  position: absolute;
  color: #e7e7e8;
  right: 14%;
}

.social-media {
  text-align: center;
  width: 90%;
}

.social-media span {
  margin: 0 10px;
}

.fa-facebook:hover {
  color: #4267b3 !important;
}

.fa-twitter:hover {
  color: #1da1f2 !important;
}

.fa-instagram:hover {
  color: #ce2b94 !important;
}

.fa-invision:hover {
  color: #f83263 !important;
}

.fa-github:hover {
  color: #161414 !important;
}

.fa-whatsapp:hover {
  color: #25d366 !important;
}

.fa-snapchat:hover {
  color: #fffb01 !important;
}

.prof {
  background-image: url("../../assets/stars.jpg");
  background-size: cover;
  color: white;
  height: 100vh;
}
a {
  color: white;
}
.sidenav-url {
  margin-top: 100px;
}
@media (max-width: 480px) {
  .sidenav {
    display: none;
  }
  .main {
    padding: auto;
    margin: auto;
    margin-top: 25%;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100vh;
  }
  .title {
    left: 10%;
  }
  #tabb {
    margin-top: 10px;
  }
  #head1,
  #head2 {
    text-align: center;
  }
  #card1,
  #card2 {
    margin-left: 0;
    padding-left: 27px;
    padding-right: 10px;
  }
}
@media (min-width: 480px) {
  #tabb {
    display: none;
  }
}
.sidenav , .navbar-top,.card{
    backdrop-filter: blur(3px);
}
.title , .name, .job,#head1,#head2{
    font-family: 'Georgia', serif;
}
.topNav{
    font-family: 'Arial', sans-serif;
}

/* End */
